@import '../../utils';
#home {
  position: absolute;
  top: 0;
  @include mobile {
    top: -50px;
  }
}
.banner {
  background-image: url('/vr image.png');
  background-repeat: no-repeat;
  background-size: cover;
  // background-position: -2.6vw -6vw;
  height: 56vw;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  @include mobile {
    margin-top: 50px;
    // height: 61vw;
  }
  @include tablet {
    margin-bottom: 2.5rem;
  }
  
  @media screen and (max-width: 500px) {
    // align-items: flex-start;
  }
}

.bg-one {
  max-width: 100%;
  position: absolute;
  left: -4.2vw;
  bottom: 6.4vw;
  z-index: 0;
}

.bg-two {
  max-width: 100%;
  position: absolute;
  left: 0;
  bottom: 9.3vw;
  z-index: 0;
}

.bg-three {
  position: absolute;
  right: -8vw;
  top: -13.6vw;
  width: 60vw;
  @include mobile {
    width: 65vw;
  }
}

.bg-mask-1 {
  position: absolute;
  right: -10vw;
  top: -12.6vw;
  width: 60.36vw;
  
  @include mobile {
    width: 65.36vw;
  }
}

.banner-play-btn {
  position: absolute;
  // @include top(323);
  // @include right(363);
  top: 14vw;
  right: 14vw;
  @include width(124);
  @include height(146);

}

.banner-content {
  text-align: left;
  color: white;
  z-index: 2;
  padding-left: 7vw;

  h1 {
    font-family: Raleway;
    // @include font('Raleway');
    @include font-size(50);
    // font-size: 50px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
  }

  p {
    font-family: Raleway;
    @include font-size(24);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    &.hide-tab {
      display: block !important;
      @include mobile{
        display: none !important;
      }
    }
  }

  .contact-btn {
    @include contact-btn;
    margin-top: 3vw;
    cursor: pointer;
  }
}