@import '../../utils.scss';
@import '../../variables/colors';

.stack-bar-container{
    @extend .flex;
    @extend .justify-center;
    @extend .align-center;
    flex-wrap: wrap;

    @include mobile() {
        @include padding(15,0);
    }
    
    color: $tealish-two;
    //box-shadow: 0 0 5px 0 $black-16;
    @include padding(10,0);

    .tech-expertise {
        @include header-two;
        @include font-size(23);
    }

    .stack-container{
        @extend .flex;
        @extend .justify-center;
        flex-wrap: wrap;
        img{
            max-height: 50px;
            @include margin(10, 10)
        }

        @include mobile(){
          display: grid;
          grid-template-columns: repeat(2,1fr);
          gap: 10px;
          place-items: center;
          img{
              
              max-height: 45px;
          }
        }
    }
}