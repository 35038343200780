@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Raleway:400,600,700&display=swap');
@import './utils.scss';

* {
  outline   : none;
  box-sizing: border-box;
}

.App {
  text-align: center;
  @extend .flex;
  @extend .justify-center;
  @extend .align-center;
  // height  : 70vh;
  // overflow: auto;
  font-family: "Nunito Sans";
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction  : column;
  align-items     : center;
  justify-content : center;
  font-size       : calc(10px + 2vmin);
  color           : white;
}

.App-link {
  color: #09d3ac;
}

p {
  margin: 0;
}

@include mobile {
  html {
    font-size: 8.5px;
  }
}

@include tablet {
  html {
    font-size: 14px;
  }
}

@include desktop {
  html {
    font-size: 15px;
  }
}

@include hd {
  html {
    font-size: 16px;
  }
}

@include hdPlus {
  html {
    font-size: 18px;
  }
}

@include fullHd {
  html {
    font-size: 20px;
  }
}

@include uHd {
  html {
    font-size: 25px;
  }
}

input,
label,
textarea,
select {
  @include mobile {
    font-size: 16px;
    margin   : 1rem 0;
  }

  @include tablet {
    font-size: 1rem;
    margin   : .5rem 0;
  }
}

input,
textarea,
select {
  padding: .5rem;
}


.ignitif-name {
  font-weight: 600;
  font-style: bold;
}

.mui-textfield {
  margin-bottom: 15px;
}