@import "../../utils.scss";
@import "../../variables/colors";

form {
  width: 100%;
  text-align: left;
  max-height: 80%;
  overflow-y: auto;
  overflow-x: hidden;
}

input,
label,
textarea,
select {
  width: 100%;
  text-align: left;
}
.submit-btn {
  @include contact-btn;
  color: #000000;
  border-color: #000;
  text-align: center;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}
.alert-info {
  font-size: 1.5rem;
  color: rgba(0,0,0,.87);
  @include mobile {
    font-size: 21px;
  }
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
select {
  font-size: 16px;
  @include tablet {
    font-size: 1rem;
  }
}
.email-wrap {
  > div {
    flex: 1;
    &:first-child {
      margin-right: 1rem;
    }
  }
}
