@import "../../utils";
@import "../../variables/colors";

@mixin about-text {
  font-family: "Nunito Sans";
  @include font-size(16);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  @include mobile {
    font-size: 16px;
  }
}

#about {
  position: absolute;
  top: -4rem;
  @include mobile {
    top: -20px;
  }
}

#case-study {
  position: absolute;
  top: -8rem;
  @include mobile {
    top: -60px;
  }
}

.about-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  position: relative;

  // &#about {
  //   padding: 2.5rem 7vw;
  //   @include desktop {
  //     padding: 5rem 0 0 7vw;
  //   }
  // }
  @include mobile {
    flex-direction: column;
    padding: 2.5rem 7vw;
  }
  @include tablet {
    padding: 2.5rem 7vw;
    flex-direction: row;
  }
  @include desktop {
    padding: 0 0 1.5rem 7vw;
  }

  > div {
    width: 100%;
    flex-shrink: 0;
    @include desktop {
      width: calc(50% - 2.5rem);
    }
  }

  &.about-two {
    @include desktop {
      padding: 0rem 7vw 0 0;
    }

    .about-img-container {
      @include desktop {
        @include margin(0, 47, 0, 0);
      }
    }

    .about-text-container {
      @include desktop {
        margin: 4rem 0 0 3vw;
      }
      @include mobile {
        margin: 0;
      }
    }
    @include mobile {
      margin: 0;
      padding: 2.5rem 7vw;
      flex-direction: column-reverse;
    }
  }
}

.about-text-container {
  position: relative;
  text-align: left;
  @include desktop {
    margin: 4rem 3vw 0 0;
  }

  h2 {
    @include header;
    margin: 0 0 1.33rem;
  }

  p,
  li {
    @include about-text;
  }
  ul {
    padding-left: 4rem;
    @include tablet {
      padding-left: 2rem;
    }
    li {
      list-style-type: disc;
    }
  }

  h4 {
    @include header-two;
    color: black;
    margin-top: 0;
  }
  @include mobile {
    margin: 2.5rem 0;
  }
}

.about-img-container {
  @include mobile {
    display: none;
  }
  @include tablet {
    display: none;
  }
  @include desktop {
    display: block;
    margin: 0;
  }

  // img {
  //   max-width: 100%;
  //   z-index: 2;
  // }

  .img-rt {
    @include width(915);
    @include height(878);
  }

  .about-bg {
    @include mobile {
      // display: none;
    }
    @include desktop {
      width: 120%;
      // height: 100%;
      background-color: $greenish-teal;
      z-index: 1;
      border-radius: 7vw 0 0 7vw;
      transform: rotate(-15deg);
    }

    &.two {
      @include desktop {
        transform: rotate(15deg) translateX(-13%);
        border-radius: 0 7vw 7vw 0;
        width: 115%;
      }
    }
  }

  .about-bg-img {
    width: 100%;
    height: 71vw;
    background-image: url("/Mask Group 2@3x.png");
    z-index: 2;
    background-size: contain;
    background-repeat: no-repeat;
    @include desktop {
      transform: rotate(15deg);
      width: 50vw;
      height: 40vw;
    }

    &.two {
      background-image: url("/products image.png");
      @include desktop {
        transform: rotate(-15deg);
      }
    }
    @include mobile {
      height: 100vw;
      background-size: cover;
      background-position: center;
    }
  }
  .quote {
    font-family: "Nunito Sans";
    @include font-size(35);
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-top: 4rem;
    padding: 0 1rem 0 9rem;
    font-weight: normal;
    color: gray;
    position: relative;
    display: flex;
    > img {
      width: 2.5rem;
      position: relative;
      &:first-child {
        top: -2.5rem;
      }
      &:last-child {
        bottom: -2.6rem;
        left: 0.5rem;
      }
    }
    @include mobile {
      font-size: 2.2rem;
      line-height: normal;
      text-align: center;
    }
  }
  @include mobile {
    margin: 0;
  }
}

.about-sub-section {
  background-color: $duck-egg-blue;
  @include padding(27);
  border-radius: 8px;

  h3 {
    @include header-three;
  }
}

.product-wrap {
  display: flex;
  justify-content: space-between;
  @include mobile {
    flex-direction: column;
  }
}

.about-right-img {
  display: block;
  max-width: 60vw;
  margin-right: -14vw;
  @include mobile {
    display: none;
  }
}
