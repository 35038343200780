@import "../../utils.scss";

.project-wrap {
  .modal-content {
    max-width: unset;
    padding: 0;

    @include tablet {
      max-width: unset;
      width: 85%;
      max-height: 100%;
    }

    @include mobile {
      width: 95%;
    }

    .custom-component {
      text-align: center;
    }

    .header-image {
      width: 100%;
      max-height: 70vh;
      object-fit: cover;
      object-position: top;
      @include mobile() {
        max-height: 30vh;
      }
    }

    .text-modal {
      text-align: center;
    }

    .project-title {
      @include header;
      padding-left: 1rem;
      @include margin(5, 0);
    }

    .modal-underline {
      margin: 2px auto;
      height: 5px;
      border-color: $tealish-two;
      width: 75%;
    }

    .services-list-container {
      @extend .flex;
      @extend .justify-center;
      @extend .align-center;
      flex-wrap: wrap;

      .services-text {
        @include header-three;
        margin: 1rem 1rem 1rem 0;
      }
      .services-list {
        margin-bottom: 0;
        display: flex;

        @include mobile() {
          width: 100%;
          justify-content: space-evenly;
        }
        .service {
          // display: inline-block;
          margin: 0 1.5rem;
          text-transform: capitalize;
          position: relative;
          display: flex;
          justify-content: center;
          @include normal-text;
          font-size: 1rem;

          @include mobile {
            margin: 0;
            padding: 1px 5px;
          }

          &::before {
            content: "";
            position: absolute;
            left: -22px;
            top: -2px;
            width: 10px;
            height: 20px;
            border: solid $tealish;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);

            @include mobile {
              width: 5px;
              height: 10px;
              top: 5px;
              left: -10px;
            }
          }
        }
      }
    }

    .project-desc {
      @include normal-text;
      @include font-size(20);
      @include padding(20, 15, 20);
      text-align: center;
    }
  }
}

.modal-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.modal-content {
  position: relative;
  width: 80%;
  height: auto;
  max-height: 90%;
  overflow: auto;
  margin: auto;
  background: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  border-radius: 0px;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include sMobile {
    width: 90%;
  }
  @include tablet {
    width: 50%;
    max-width: 500px;
  }
}

.close-btn {
  position: absolute;
  top: 1rem;
  right: 2rem;
  cursor: pointer;
}
.modal-title {
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.87);
  @include mobile {
    font-size: 21px;
  }
}
