@import '../../utils.scss';
@import '../../variables/colors';

.testimonial-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 7vw;
  text-align: left;

  h2 {
    @include header;
    width: 100%;
    text-align: left;
  }
  @include mobile {
    padding: 2.5rem 7vw;
  }
}

.testimonial-box {
  display: flex;
  align-items: center;
  @include padding(41,54,62);
  @include width(1247);
  max-width: 100%;
  //@include height(188);
  min-height: 320px;
  box-shadow: 0 3px 6px 0 $black-16;
  background-color: white;
  @include mobile {
    height: auto;
    flex-direction: column;
  }

}

.test-icon {
  @extend .flex;
  .icon-quotes{
    max-height: 3rem;
  }

  .user-image{
    max-height: 10rem;
    border-radius: 50%
  }
  @include margin(0,61,0,0);
  @include mobile {
    flex-direction: column;
    .icon-quotes {
      margin-bottom: 0.85rem;
    }
    .user-image{
      max-height: 15rem;
    }
    max-width: 50vw;
    margin-bottom: 1rem;
  }
}

.test-arrow-wrap {
  display: flex;
  @include mobile {
    margin-top: 1rem;
  }
}

.test-text {
  @include normal-text;
  @include font-size(21);
}

.user-name{
  @include header-two;
  margin-bottom: 0.5rem;
}

.user-company{
  @include normal-text;
  @include font-size(18)
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  @include width(43);
  @include height(43);
  @include margin(24);
  border: solid 1px $emerald;
  border-radius: 50%;
  cursor: pointer;

  &:nth-child(even) {
    transform: rotate(180deg);
  }
  @include mobile {
    width: 4rem;
    height: 4rem;
  }
}
