@import "../../utils.scss";
@import "../../variables/colors";

#services {
  position: absolute;
  top: -95px;
  @include mobile {
    top: -50px;
  }
}
.services-container {
  position: relative;
  display: flex;
  padding: 0 7vw;
  flex-direction: column;
  @include desktop {
    flex-direction: row;
    padding: 0 7vw 0rem 0;
  }
}

.services-content,
.services-items {
  flex: 1;
  @include mobile {
    // flex: auto;
    width: 100%;
    flex-direction: column;
  }
}

.services-content {
  text-align: left;
  // padding: 1rem;
  @include desktop {
    @include padding(0, 0, 0, 0);
  }
  @include hd {
    @include padding(0, 0, 0, 0);
  }

  h2 {
    @include header;
    @include desktop {
      padding-left: 5rem;
    }
  }

  p {
    color: $black-two;
    @include normal-text;
    @include desktop {
      padding: 0 2rem 0 5rem;
    }
  }
}

.services-items {
  // flex-wrap: wrap;
  display: flex;
}

.service-col {
  display: flex;
  flex: 1;
  flex-direction: column;

  &:last-child {
    @include tablet {
      @include padding(50, 0, 0);
    }
  }
  @include mobile {
    // flex-direction: row;
    // justify-content: space-between;
  }
  @include tablet-only {
    &:last-child {
      align-items: flex-end;
    }
  }
}

.services-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  // @include width(316);
  width: 46%;
  @include min-height(334);
  box-shadow: 0 0 30px 0 $black-16;
  @include padding(34, 25, 21);
  @include margin(27, 0);
  overflow: hidden;
  @include mobile {
    width: 100%;
    margin: 1.35rem 0;
    padding: 2rem;
  }

  &.hover {
    @include width(402);
    @include min-height(334);
    @include mobile {
      width: 100%;
      margin: 1.35rem 0;
      padding: 2rem;
    }
    @include tablet {
      width: 95%;
    }
    @include desktop {
      @include width(280);
    }
    @include hd {
      @include width(316);
    }

    &:hover {
      box-shadow: 0 0 10px 0 $black-16;
      background-image: linear-gradient(131deg, $greenish-teal -17%, $greenish-cyan 100%);

      h4 {
        color: white;
      }
    }
  }

  .img-wrap {
    background-color: $white-two;
    @include width(85);
    @include height(85);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 50%;
    @include mobile {
      width: 7rem;
      height: 7rem;
    }
  }

  img {
    @include width(50);
    @include mobile {
      width: 4rem;
    }
  }

  h4 {
    @include header-two;
    margin-top: 0;
  }

  p {
    font-family: "Nunito Sans";
    @include normal-text;
  }
}

.customer-img {
  display: none;
  @include desktop {
    display: block;
    max-width: 60vw;
    margin-left: -14vw;
  }
}
