$black-90: rgba(0, 0, 0, 0.9);
$white-two: #f5f5f5;
$emerald: #008e5c;
$black-two: #101010;
$black-16: rgba(0, 0, 0, 0.16);
$greenish-teal:#2bc388;
$greenish-cyan:#22ffa9;
$tealish-two: #1fb4ad;
$duck-egg-blue:#eafcfb;
$jungle-green: #088e5c;
$tealish: #1dc7c2;
$emerald: #008e5c;
$dark-blue-grey: #154341;