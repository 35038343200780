@import './variables/colors';
@import './variables/breakpoints';

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.align-top {
  align-items: flex-start;
}

$conversion-factor: 20;
@mixin font-size($size) {
  font-size: $size / $conversion-factor + rem;
}
@mixin width($value) {
  width: $value / $conversion-factor + rem;
}
@mixin max-width($value) {
  max-width: $value / $conversion-factor + rem;
}
@mixin height($value) {
  height: $value / $conversion-factor + rem;
}
@mixin min-height($value) {
  min-height: $value / $conversion-factor + rem;
}
@mixin padding ($value1, $value2:'', $value3:'', $value4:'') {
   @if($value2==''){
    padding: $value1 / $conversion-factor + rem;
  }
  @else if($value3=='') {
    padding: $value1 / $conversion-factor + rem $value2 / $conversion-factor + rem;
  }
  @else if($value4=='') {
    padding: $value1 / $conversion-factor + rem $value2 / $conversion-factor + rem $value3 / $conversion-factor + rem;
  }
  @else {
    padding: $value1 / $conversion-factor + rem $value2 / $conversion-factor + rem $value3 / $conversion-factor + rem $value4 / $conversion-factor + rem;
  }
}
@mixin margin ($value1, $value2:'', $value3:'', $value4:'') {
   @if($value2==''){
    margin: $value1 / $conversion-factor + rem;
  }
  @else if($value3=='') {
    margin: $value1 / $conversion-factor + rem $value2 / $conversion-factor + rem;
  }
  @else if($value4=='') {
    margin: $value1 / $conversion-factor + rem $value2 / $conversion-factor + rem $value3 / $conversion-factor + rem;
  }
  @else {
    margin: $value1 / $conversion-factor + rem $value2 / $conversion-factor + rem $value3 / $conversion-factor + rem $value4 / $conversion-factor + rem;
  }
}
@mixin border-radius($value1, $value2:'', $value3:'', $value4:'') {
   @if($value2==''){
    border-radius: $value1 / $conversion-factor + rem;
  }
  @else if($value3=='') {
    border-radius: $value1 / $conversion-factor + rem $value2 / $conversion-factor + rem;
  }
  @else if($value4=='') {
    border-radius: $value1 / $conversion-factor + rem $value2 / $conversion-factor + rem $value3 / $conversion-factor + rem;
  }
  @else {
    border-radius: $value1 / $conversion-factor + rem $value2 / $conversion-factor + rem $value3 / $conversion-factor + rem $value4 / $conversion-factor + rem;
  }
}
@mixin top($size) {
  top: $size / $conversion-factor + rem;
}
@mixin bottom($size) {
  bottom: $size / $conversion-factor + rem;
}
@mixin left($size) {
  left: $size / $conversion-factor + rem;
}
@mixin right($size) {
  right: $size / $conversion-factor + rem;
}
@mixin header {
  font-family: Raleway;
  @include font-size(40);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  color: $tealish-two;
  margin: 1.33rem 0;
  @include mobile {
    font-size: 3rem;
  }
}
@mixin header-two {
  font-family: Raleway;
  @include font-size(20);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: .02px;
  color: $emerald;
  margin: 1.33rem 0;
  text-align: left;
  @include mobile {
    font-size: 2.2rem;
  }
}

@mixin header-three {
  font-family: Raleway;
  @include font-size(30);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: .02px;
  color: $emerald;
  margin: 1.33rem 0;
  text-align: left;
  @include mobile {
    font-size: 2.2rem;
  }
}

@mixin header-four {
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: left;
  color: white;
  margin: 1.2rem 0;
}
@mixin normal-text {
  color: $black-two;
  @include font-size(16);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-align: left;
  @include mobile {
    font-size: 16px;
  }
}

@mixin contact-btn {
  @include width(212);
  // width: 212px;
  @include height(65);
  // height: 65px;
  @include border-radius(40);
  background-color: transparent;
  border: 1px solid white;
  font-family: Raleway;
  @include font-size(24);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: white;
}

// mixins for responsive styling
@mixin sMobile {
  @media #{$sMobile}{
   @content;
 }
}
@mixin mobile {
   @media #{$mobile}{
    @content;
  }
}
@mixin tablet {
   @media #{$tablet}{
    @content;
  }
}
@mixin tablet-only {
  @media #{$tablet-only}{
   @content;
 }
}
@mixin desktop {
   @media #{$desktop}{
    @content;
  }
}
@mixin hd {
   @media #{$hd}{
    @content;
  }
}
@mixin hdPlus {
   @media #{$hd-plus}{
    @content;
  }
}
@mixin fullHd {
   @media #{$full-hd}{
    @content;
  }
}
@mixin uHd {
   @media #{$u-hd}{
    @content;
  }
}
@mixin mobileLandscape {
   @media #{$mobile-landscape}{
    @content;
  }
}
@mixin tabletOnly {
   @media #{$tablet-only}{
    @content;
  }
}

// mixins for responsive styling ends here


.hide-mobile {
  display: none !important;
  @include tablet {
    display: flex !important;
  }
}

.hide-tab {
  display: none !important;
  @include desktop {
    display: flex !important;
  }
}