@import '../../utils.scss';

.footer {
  @extend .flex;
  @extend .justify-center;
  @extend .align-top;
  background-image: url('/footer@3x-1.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  color: white;
  @include min-height(500);
  object-fit: contain;
  @include padding(200, 88, 100);
  @include mobile {
    flex-direction: column;
    padding: 4rem 2rem 5rem;
    background: $dark-blue-grey;
  }
  @include tablet {
    padding: 6rem 3vw 3rem;
  }

  h4 {
    font-family: Raleway;
    @include font-size(22);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
  }

  p {
    @include font-size(14);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
  }
}

.footer-col {
  display: flex;
  flex-direction: column;
  flex: 1;
  @include padding(0,30);
  @include desktop {
     @include padding(0,40);
  }

  &:first-child {
    flex: 1.5;
  }

  ul {
    margin: 0;
  }

  h4 {
    @include header-four;
  }

  p {
    font-family: 'Nunito Sans';
    @include font-size(16.5);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;

    a {
      color: white;
    }
    @include mobile {
      font-size: 12px;
    }
  }
}

.social-icon {
  margin-top: 2rem;

  a {
    margin: 0 .25rem;

    &:first-child {
      @include desktop {
        margin: 0 .25rem 0 0;
      }
    }
  }
}
