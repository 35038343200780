@import '../../utils.scss';
@import '../../variables/colors';

.case-study-container {
	position: relative;
	display: flex;
	flex-direction: column;
	text-align: left;
	padding: 0rem 7vw 1rem;
	scroll-margin-top: 4rem;

	h2 {
		@include header;
		@include tablet {
			margin-left: 0.5vw;
			margin-right: 2vw;
		}
	}

	> p {
		@include normal-text;
		@include desktop {
			max-width: 60%;
			margin-left: 2vw;
			margin-right: 2vw;
		}
	}
	@include mobile {
		padding: 3rem 8.5vw;
		margin-bottom: 2rem;
		//display: none;
	}
}

.project-wrap {
	display: block;
	@include desktop {
		justify-content: center;
	}

	.slick-slide {
		@include margin(0, 20);

		@include mobile() {
			margin: 0;
		}
	}

	.slick-arrow {
		height: 35px;
		width: 35px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: solid 1px $emerald;
		border-radius: 50%;
		cursor: pointer;

		@include mobile() {
			height: 25px;
			width: 25px;
		}
		// background-color: $tealish;

		img {
			position: relative;
			top: 10px;
			left: 10px;

			@include mobile() {
				top: 5px;
				left: 5px;
			}
		}

		&::before {
			content: '';
			display: none;
		}
	}

	.right-arrow {
		transform: rotate(180deg);
	}

	.slick-prev {
		left: -45px;

		@include mobile() {
			left: -30px;
		}
	}

	.slick-next {
		transform: rotate(180deg);
		right: -40px;

		@include mobile() {
			right: -30px;
		}
	}

	.carousel-cards {
		position: relative;
		cursor: pointer;
		-webkit-transition: all 0.4s ease-in-out 0s;
		-moz-transition: all 0.4s ease-in-out 0s;
		transition: all 0.4s ease-in-out 0s;
		img {
			//margin: 10px;
			width: 100%;
			height: 20rem;
			border-radius: 5px;
			box-shadow: 0 0 15px 0 $black-16;
			//padding: 1rem;
			object-fit: cover;
			object-position: 0 0;

			@include mobile() {
				height: 30rem;
				width: 100%;
			}
		}

		&:hover {
			.content-overlay {
				opacity: 0.9;
				box-shadow: 0 0 35px 0 $black-16;
			}

			.content-details-overlay {
				top: 50%;
				left: 50%;
				opacity: 1;
			}
		}

		.content-overlay {
			background: rgba(0, 0, 0, 0.9);
			position: absolute;
			height: 20rem;
			border-radius: 5px;
			//margin: 10px;
			width: 100%;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			opacity: 0;
			-webkit-transition: all 0.4s ease-in-out 0s;
			-moz-transition: all 0.4s ease-in-out 0s;
			transition: all 0.4s ease-in-out 0s;

			@include mobile() {
				height: 30rem;
				width: 100%;
			}
		}

		.content-details-overlay {
			position: absolute;
			text-align: center;
			padding-left: 1em;
			padding-right: 1em;
			width: 90%;
			top: 50%;
			left: 50%;
			opacity: 0;
			-webkit-transform: translate(-50%, -50%);
			-moz-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			-webkit-transition: all 0.3s ease-in-out 0s;
			-moz-transition: all 0.3s ease-in-out 0s;
			transition: all 0.3s ease-in-out 0s;
			color: white;
			font-size: 25px;
			top: 20%;
		}

		.short-desc {
			@include normal-text;
			text-align: center;
			// @include font-size(16);
			color: $white-two;
			display: -webkit-box;
			display: -moz-box;
			line-clamp: 2;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			width: 100%;
			overflow: hidden;
		}

		.read-more-btn {
			background-color: $tealish;
			padding: 5px 15px;
			font-size: 15px;
			outline: none;
			border-style: none;
			cursor: pointer;
			color: #fff;
			//font-family: 'Nunito Regular', sans-serif;
		}
	}
}

.project-item {
	height: 20vw;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	@include padding(20);
	border-radius: 5px;
	box-shadow: 0 0 15px 0 $black-16;
	background-color: white;
	width: calc(50% - 4.5rem);
	margin: 1.25rem 0;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	background-position: left top;

	&:hover {
		box-shadow: 0 0 35px 0 $black-16;
		.project-detail {
			opacity: 1;
			height: 20%;
			overflow: visible;
			padding: 2rem;
		}

		p {
			display: block;
			color: white;
		}
	}
	@include tablet {
		@include height(400);
	}
	@include desktop {
		max-width: 50%;
		@include margin(25);
	}
}
.project-detail {
	opacity: 1;
	height: 20%;
	overflow: visible;
	padding: 2rem;
	position: absolute;
	left: 0;
	bottom: 0;
	height: 0;
	width: 100%;
	background-color: rgba($color: #000000, $alpha: 0.7);
	opacity: 0;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding: 0;
	transition: all 0.3s ease-in-out;
	> p {
		// display: none;
		@include font-size(14.5);
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.18;
		letter-spacing: normal;
		color: white;
	}
}
